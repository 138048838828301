<template>
  <section class="hero hero-style-1">
    <carousel
        :dots="false"
        :nav="false"
        :autoHeight="false"
        :items="1"
    >
      <template slot="prev">
        <button type="button" class="slick-prev slick-arrow">Previous</button>
      </template>

      <div class="slide" :style="`background-image: url(${ carousel.background_image })`" v-for="carousel in carouselItems" :key="carousel.id">
        <template v-if="carousel.status === 'published'">
          <div class="container" data-aos="fade-left" data-aos-duration="600">
            <img src="../assets/images/slider/slide-1.jpg" alt class="slider-bg">
            <div class="row">
              <div class="col col-md-6 slide-caption">
                <div class="slide-title">
                  <h2>{{ carousel.title }}</h2>
                </div>
                <div class="slide-subtitle">
                  <p>{{ carousel.description }}</p>
                </div>
                <div class="btns">
                  <a :href="carousel.button1_url" v-if="carousel.button1_url && carousel.button1_text.trim()" target="_blank" class="theme-btn">
                    {{ carousel.button1_text }}
                  </a>
                  <a :href="carousel.button2_url" v-if="carousel.button2_url && carousel.button2_text.trim()" target="_blank" class="theme-btn-s2">
                    {{ carousel.button2_text }}
                  </a>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <template slot="next" v-if="carouselItems.length > 1">
        <button type="button" class="slick-next slick-arrow" style="display: block;">Next</button>
      </template>

    </carousel>
  </section>
</template>

<script>
    import carousel from 'vue-owl-carousel';
    import api from '../services/api'
    export default {
        name: "hero",
        data() {
            return {
                carouselItems: []
            }
        },
        components: {
            carousel
        },
        props: ['headerCarousel'],
        async created() {
            this.carouselItems = this.headerCarousel;

            for (const value of this.carouselItems) {
                if (value.background_image !== null && typeof value.background_image === "number") {
                    let img = await api.getFiles(value.background_image);
                    if (img !== null) {
                        value.background_image = img.data['data']['full_url'];
                    }
                }
            }
        }
    }
</script>

<style scoped>
  .slide {
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
