<template>
  <div class="wpo-world-area" data-aos="fade-up" data-aos-duration="600">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wpo-world-section">
            <h2>Help Us Stop Dating Violence</h2>
            <a href="https://www.paypal.com/donate/?hosted_button_id=J2JXZJ8WMLJJG">Support Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "world-area",
    }
</script>

<style scoped>
  .wpo-world-area {
    margin-bottom: 50px;
  }
</style>
