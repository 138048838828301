<template>
  <div class="wpo-case-area section-padding wpo-case-slider" data-aos="fade-up" data-aos-duration="600">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wpo-section-title">
            <h2>Popular Causes You Should Know</h2>
          </div>
        </div>
      </div>
      <div class="wpo-case-wrap">
        <carousel
            :dots="false"
            :nav="true"
            :autoHeight="false"
            :responsive="{0:{items:1},400:{items:1},1024:{items:3}}"
            :margin="15"
            :loop="false"
            :navText="[`<i class='fi ti-angle-left'></i>`,`<i class='fi ti-angle-right'></i>`]"
        >
          <div class="wpo-case-single" v-for="cause in causes_data" :key="cause.id">
            <div class="wpo-case-item">
              <div class="wpo-case-img">
                <div class="default-img">
                  <img :src="cause.image" @error="setAltImg" alt="" @click="openModal(cause)">
                </div>
              </div>
              <div class="wpo-case-content">
                <div class="wpo-case-text-top">
                  <h2 @click="openModal(cause)">{{cause.title}}</h2>
                  <ul>
                    <li><i class="fa fa-clock-o" aria-hidden="true"></i>{{cause.published_date | moment("MMMM DD, YYYY")}}</li>
                  </ul>
                </div>
                <div class="case-btn">
                  <ul>
                    <li><a :href="cause.button_1_url" v-if="cause.button_1_url && cause.button_1_text.trim()" target="_blank">{{cause.button_1_text}}</a></li>
                    <li><a :href="cause.button_2_url" v-if="cause.button_2_url && cause.button_2_text.trim()" target="_blank">{{cause.button_2_text}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </carousel>
      </div>
    </div>
    <modal name="modalCauses" :width="620" :height="600" :adaptive="true">
      <div class="wpo-team-modal">
        <a @click="closeModal()" class="close-btn"><i data-v-aa42a3de="" class="ti-close"></i></a>
        <div class="row mb-50">
          <div class="col-sm-12 col-xs-12 text-left">
            <div>
              <h2 class="wpo-team-title">{{ modal.title }}</h2>
              <p class="wpo-cause-text">{{ modal.description }}</p>
            </div>
          </div>
        </div>
        <!--<div>
          <p class="text-left">{{ modal.description }}</p>
        </div> -->
      </div>
    </modal>
  </div>
</template>

<script>
    import carousel from 'vue-owl-carousel';
    import api from "../services/api";
    export default {
        name: "case-area",
        data() {
            return {
                causes_data: [],
                modal: {
                  image: '',
                  title: '',
                  description: ''
                }
            }
        },
        components: {
            carousel
        },
        props: ['causes'],
        async created() {
            this.causes_data = this.causes;
            for (const value of this.causes_data) {
                if (value.image !== null && typeof value.image === "number") {
                    let img = await api.getFiles(value.image);
                    if (img !== null) {
                        value.image = img.data['data']['full_url'];
                    }
                }
            }
        },
        methods: {
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
            openModal(cause) {
                this.modal.image = cause.image;
                this.modal.title = cause.title;
                this.modal.description = cause.description;
                this.$modal.show('modalCauses');
            },
            closeModal() {
                this.$modal.hide('modalCauses');
            }
        }
    }
</script>

<style scoped>
  .wpo-case-text-top {
    min-height:195px;
  }

  .wpo-case-text-top ul li {
    margin-right: 20px;
  }

  .wpo-case-content h2 {
      font-size: 20px;
      cursor:pointer;
  }

  .wpo-cause-text {
    padding:10px 20px;
    white-space: pre-wrap;
  }

  .wpo-case-text-top ul li i {
    font-size: 15px;
    margin-right: 10px;
  }

  .default-img {
    width: 100%;
    height: 274px;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }

  .default-img img {
    height: 100%;
    object-fit: cover;
  }

  .case-btn ul {
    display:block;
  }

  .close-btn {
    float: right;
    padding-bottom: 10px;
    cursor: pointer;
  }
</style>
