<template>
  <div class="wpo-counter-area" v-if="introduction.status === 'published'">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wpo-counter-grids">
            <div class="grid" v-if="introduction.counter_1">
              <div>
                <h2>
                  <number
                      ref="number1"
                      :from="0"
                      :to="introduction.counter_1"
                      :format="theFormat"
                      :duration="2"
                      :delay="2"
                      easing="Power1.easeOut"></number>+
                </h2>
              </div>
              <p>{{introduction.counter_1_text}}</p>
            </div>
            <div class="grid" v-if="introduction.counter_2">
              <div>
                <h2>
                  <number
                      ref="number1"
                      :from="0"
                      :to="introduction.counter_2"
                      :format="theFormat"
                      :duration="2"
                      :delay="2"
                      easing="Power1.easeOut"></number>+
                </h2>
              </div>
              <p>{{introduction.counter_2_text}}</p>
            </div>
            <div class="grid" v-if="introduction.counter_3">
              <div>
                <h2>
                  <number
                      ref="number1"
                      :from="0"
                      :to="introduction.counter_3"
                      :format="theFormat"
                      :duration="2"
                      :delay="2"
                      easing="Power1.easeOut"></number>+
                </h2>
              </div>
              <p>{{introduction.counter_3_text}}</p>
            </div>
            <div class="grid" v-if="introduction.counter_4">
              <div>
                <h2>
                  <number
                      ref="number1"
                      :from="0"
                      :to="introduction.counter_4"
                      :format="theFormat"
                      :duration="2"
                      :delay="2"
                      easing="Power1.easeOut"></number>+
                </h2>
              </div>
              <p>{{introduction.counter_4_text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "counter-area",
        props: ['introduction'],
        methods: {
            theFormat(number) {
                return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
            },
        }
    }
</script>
