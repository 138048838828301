<template>
  <div>
    <hero :headerCarousel="headerCarousel_data" v-if="headerCarousel_data.length !== 0"/>
    <div class="gooey" v-if="headerCarousel_data.length === 0" style="height: 900px"></div>

    <aboutArea :introduction="introduction_data" :buttonStatus="true" v-if="introduction_data.length !== 0"/>
    <div class="gooey" v-if="introduction_data.length === 0" style="height: 795px">
      <img src="../assets/images/Infinity-loader.gif" alt="">
    </div>

    <caseArea :causes="causes_data" v-if="causes_data.length !== 0" />
    <div class="gooey" v-if="causes_data.length === 0" style="height: 1005px">
      <img src="../assets/images/Infinity-loader.gif" alt="">
    </div>

    <counterArea :introduction="introduction_data" v-if="introduction_data.length !== 0 && counter"/>
    <div class="gooey" v-if="introduction_data.length === 0" style="height: 332px">
      <img src="../assets/images/Infinity-loader.gif" alt="">
    </div>

    <teamArea :team="team_data" v-if="team_data.length !== 0" />
    <div class="gooey" v-if="team_data.length === 0" style="height: 735px">
      <img src="../assets/images/Infinity-loader.gif" alt="">
    </div>

    <worldArea/>
    <eventArea :events="events_data" v-if="events_data.length !== 0"/>
    <div class="gooey" v-if="team_data.length === 0" style="height: 859px">
      <img src="../assets/images/Infinity-loader.gif" alt="">
    </div>

  </div>
</template>

<script>
  import hero from '../components/hero'
  import aboutArea from '../components/about-area'
  import caseArea from '../components/case-area'
  import counterArea from '../components/counter-area'
  import teamArea from '../components/team-area'
  import worldArea from '../components/world-area'
  import eventArea from '../components/event-area'
  import api from '../services/api'

  export default {
    name: "home",
    data() {
      return {
        headerCarousel_data: [],
        introduction_data: [],
        team_data: [],
        events_data: [],
        contact_data: [],
        causes_data: [],
        counter: false,
        scroll1: true,
        scroll2: true,
        scroll3: true,
      }
    },
    components: {
      hero,
      aboutArea,
      caseArea,
      counterArea,
      teamArea,
      worldArea,
      eventArea
    },
    async mounted() {
      window.addEventListener('scroll', this.handleScroll);
      window.scrollTo(0, 0);

      this.contact_data = await api.contact('button_text,button_url');
      this.contact_data = this.contact_data.data[0];

      this.headerCarousel_data = await api.headerCarousel();
      this.headerCarousel_data = this.headerCarousel_data.data;

      this.events_data = await api.events();
      this.events_data = this.events_data.data;
    },
    methods: {
      handleScroll: async function  () {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        scrollPosition = scrollPosition + window.innerHeight;
        if(scrollPosition >= 1500 && this.scroll1) {
          this.scroll1 = false;
          this.introduction_data = await api.mission('id,status,about_image,about_title,about_description,counter_1,counter_2,counter_3,counter_4,counter_1_text,counter_2_text,counter_3_text,counter_4_text');
          this.introduction_data = this.introduction_data.data[0];
        }
        if (scrollPosition >= 1800 && this.scroll2) {
          this.scroll2 = false;
          this.causes_data = await api.causes('id,status,published_date,title,image,button_1_text,button_1_url,button_2_text,button_2_url,description');
          this.causes_data = this.causes_data.data;
        }
        if (scrollPosition >= 1900) {
          this.counter = true;
        }
        if (scrollPosition >= 3500 && this.scroll3) {
          this.scroll3 = false;
          this.team_data = await api.team();
          this.team_data = this.team_data.data;
        }
      }
    }
  }
</script>

<style scoped>
  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
