<template>
  <div class="wpo-team-area mt-90" data-aos="fade-up" data-aos-duration="600">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wpo-section-title">
            <span>Meet Our Team</span>
            <h2>Who Support Asia Adams</h2>
          </div>
        </div>
      </div>
      <div class="row d-flex flex-wrap">
        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" v-for="member in teamMembers" :key="member.id">
          <div class="wpo-team-wrap mb-90">
            <div class="wpo-team-img">
              <div class="default-img">
                <img :src="member.image" @error="setAltImg" :alt="member.name" @click="openModal(member)" />
              </div>
            </div>
            <div class="wpo-team-content">
              <h2 class="wpo-team-title mb-20" @click="openModal(member)">{{ member.name }}</h2>
              <h4 class="wpo-team-subtitle">{{ member.title }}</h4>
            </div>
            <!--
            <div class="wpo-team-content">
              <div class="wpo-team-text-sub">
                <h2>{{team.name}}</h2>
                <span>{{team.title}}</span>
                <p>{{trimMyString(team.description, 180)}}</p>
                <ul>
                  <li><a :href="team.facebook_link" v-if="team.facebook_link" target="_blank"><i class="ti-facebook"></i></a></li>
                  <li><a :href="team.twitter_link" v-if="team.twitter_link" target="_blank"><i class="ti-twitter"></i></a></li>
                  <li><a :href="team.linkedin_link" v-if="team.linkedin_link" target="_blank"><i class="ti-linkedin"></i></a></li>
                  <li><a :href="team.instagram_link" v-if="team.instagram_link" target="_blank"><i class="ti-instagram"></i></a></li>
                </ul>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <modal name="modalMember" :width="620" :height="600" :adaptive="true">
      <div class="wpo-team-modal">
       <a @click="closeModal()" class="close-btn"><i data-v-aa42a3de="" class="ti-close"></i></a>
        <div class="row d-flex mb-50">
          <div class="col-sm-4 col-xs-12">
            <div class="image">
              <img :src="modal.image" @error="setAltImg" alt="" />
            </div>
          </div>
          <div class="col-sm-8 col-xs-12 text-left">
            <div>
              <h2 class="wpo-team-title">{{ modal.name }}</h2>
              <h4 class="wpo-team-subtitle">{{ modal.title }}</h4>
              <div class="d-flex">
              <div class="row">
                <div v-if="modal.facebook_link" class="col-xs-2 social-link">
                  <a :href="modal.facebook_link" target="_blank"><i class="ti-facebook"></i></a>
                </div>
                <div v-if="modal.twitter_link" class="col-xs-2 social-link">
                  <a :href="modal.twitter_link" target="_blank"><i class="ti-twitter"></i></a>
                </div>
                <div v-if="modal.linkedin_link" class="col-xs-2 social-link">
                  <a :href="modal.linkedin_link" target="_blank"><i class="ti-linkedin"></i></a>
                </div>
                <div v-if="modal.instagram_link" class="col-xs-2 social-link">
                  <a :href="modal.instagram_link" target="_blank"><i class="ti-instagram"></i></a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="text-left">{{ modal.description }}</p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
    import api from '../services/api'
    export default {
        name: "team-area",
        data() {
            return {
                teamMembers: [],
                modal: {
                  image: '',
                  name: '',
                  title: '',
                  description: '',
                  facebook_link: '',
                  twitter_link: '',
                  linkedin_link: '',
                  instagram_link: '',
                }
            }
        },
        props: ['team'],
        async mounted() {
            this.teamMembers = this.team.map(function(teamMember) {
              if (teamMember.status === 'published') {
                return teamMember;
              }
            });

            for (const teamMember of this.teamMembers) {
                if (teamMember.image !== null && typeof teamMember.image === "number") {
                    let img = await api.getFiles(teamMember.image);
                    if (img !== null) {
                        teamMember.image = img.data['data']['full_url'];
                    }
                }
            }
        },
        methods: {
            trimMyString (string, maxLength, start = 0) {
                if (string === null) {
                    return
                }
                if (string.length > maxLength) {
                    let trimmedString = string.substr(start, maxLength)
                    return (
                        trimmedString.substr(
                            start,
                            Math.min(trimmedString.length,   trimmedString.lastIndexOf(' '))
                        ) + ' ...'
                    )
                }
                return string
            },
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
            openModal(member) {
                this.modal.image = member.image;
                this.modal.name = member.name;
                this.modal.title = member.title;
                this.modal.description = member.description;
                this.modal.facebook_link = member.facebook_link;
                this.modal.twitter_link = member.twitter_link;
                this.modal.linkedin_link = member.linkedin_link;
                this.modal.instagram_link = member.instagram_link;

                this.$modal.show('modalMember');
            },
            closeModal() {
                this.$modal.hide('modalMember');
            }
        }
    }
</script>

<style scoped>
  .default-img {
    width: 100%;
    height: 340px;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }

  .default-img img {
    height: 100%;
    object-fit: cover;
  }

  .custom-grid {
    margin-bottom: 100px;
  }

  .wpo-team-title, .wpo-team-subtitle  {
    text-align: left;
  }

  .social-link {
    padding-left: 18px;
  }
  .close-btn {
    float: right;
    padding-bottom: 10px;
    cursor: pointer;
  }
</style>
