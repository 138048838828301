<template>
  <div class="wpo-event-area section-padding" data-aos="fade-up" data-aos-duration="600">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wpo-section-title">
            <span>Our Events</span>
            <h2>Upcoming Events</h2>
          </div>
        </div>
      </div>
      <div class="row wpo-case-slider">
        <carousel
            :dots="false"
            :nav="true"
            :autoHeight="false"
            :responsive="{0:{items:1},600:{items:2},900:{items:3}}"
            :margin="15"
            :loop="false"
            :navText="[`<i class='fi ti-angle-left'></i>`,`<i class='fi ti-angle-right'></i>`]"
        >
          <div class="wpo-event-item" v-for="events in events_data.slice(0, 5)" :key="events.id">
            <template v-if="events.status === 'published'">
              <div class="wpo-event-img">
                <img :src="events.image" alt="">
              </div>
              <div class="wpo-event-text">
                <h2>{{events.title}}</h2>
                <ul v-if="checkDate(events.start_date, events.end_date)">
                  <li><i class="fa fa-clock-o" aria-hidden="true"></i>{{checkDate(events.start_date, events.end_date)}}</li>
                </ul>
                <p>{{trimMyString(events.description, 65)}}</p>
                <a :href="events.link_url">{{events.link_text}}</a>
              </div>
            </template>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../services/api';
    import carousel from 'vue-owl-carousel';
    import moment from 'moment'
    export default {
        name: "event-area",
        data() {
            return {
                events_data: []
            }
        },
        props: ['events'],
        components: {
            carousel
        },
        async created() {
            this.events_data = this.events;
            for (const value of this.events_data) {
                if (value.image !== null && typeof value.image === "number") {
                    let img = await api.getFiles(value.image);
                    if (img !== null) {
                        value.image = img.data['data']['full_url'];
                    }
                }
            }
        },
        methods: {
            checkDate(start, end) {
                if (start && end === null) {
                    return moment(start).format('MM-DD-YYYY hh:mm')
                }
                if (start === null || end === null) {
                    return ''
                }
                if (moment(start).format('MM-DD-YYYY') === moment(end).format('MM-DD-YYYY')) {
                    return moment(start).format('MM-DD-YYYY') + ' | ' + moment(start).format('hh:mm') + ' - ' + moment(end).format('hh:mm')
                } else {
                    return moment(start).format('MM-DD-YYYY hh:mm') + ' - ' + moment(end).format('MM-DD-YYYY hh:mm')
                }
            },
            trimMyString (string, maxLength, start = 0) {
                if (string.length > maxLength) {
                    let trimmedString = string.substr(start, maxLength)
                    return (
                        trimmedString.substr(
                            start,
                            Math.min(trimmedString.length,   trimmedString.lastIndexOf(' '))
                        ) + ' ...'
                    )
                }
                return string
            },
        }
    }
</script>

<style scoped>
    .wpo-event-img {
        width: 100%;
        height: 308px;
        background-image: linear-gradient(to right, #f725845e, #7108b778);
    }

    .wpo-event-img img {
        height: 100%;
        object-fit: cover;
    }

    .lode-more-div {
        height: 481px;
        background-color: rgba(168, 98, 199, 0.09);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lode-more-div a {
        padding: 5px 15px;
        border: 1px solid #a14ec6;
        color: #ffffff;
        border-radius: 5px;
        background: #a14ec6;
        text-decoration: none;
        transition: 0.3s;
    }
    .lode-more-div a:hover {
        background: #fff;
        color: #a14ec6;
    }
</style>
