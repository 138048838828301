<template>
  <div class="pb-50 section-padding" v-if="introduction_data.status === 'published'" data-aos="fade-up" data-aos-duration="600">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wpo-section-title">
            <h2>{{ introduction_data.about_title }}</h2>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="default-img">
            <img :src="introduction_data.about_image" alt="">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mt-50 mb-50" v-html="introduction_data.about_description"></div>
        </div>
        <div class="col-lg-12">
          <div v-if="buttonStatus" class="btns text-center">
            <router-link to="/about" class="theme-btn">Know More</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../services/api';
  export default {
    name: "about-area",
    data() {
      return {
        introduction_data: []
      }
    },
    props: ['introduction', 'buttonStatus'],
    async created() {
      this.introduction_data = this.introduction;
      if (typeof this.introduction_data.about_image === "number") {
          this.introduction_data.about_image = await api.getFiles(this.introduction_data.about_image);
          this.introduction_data.about_image = this.introduction_data.about_image.data['data']['full_url'];
      }
    }
  }
</script>

<style>
  .default-img {
    width: 100%;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }

  .default-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
</style>
